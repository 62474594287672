import React,{useEffect,useState} from 'react'; 
import axios from 'axios'
import './App.css';
import { setInterval } from 'timers';

//https://api.continentaltur.com.br/app/tv
const API = "https://api.continentaltur.com.br/app/tv"
 

interface IApp{
   id:number, 
   customer:string, 
   driver: string, 
   route?:string, 
   className?:string, 
   plate?:string, 
    
}



function App() {

  const [apps,setApps]=  useState<IApp[]>([])

  function Apps(params:Object){

    const config = {
      params, 
      headers:{
      "Content-Type":"application/json"
      }
    }
  
    axios.get(API,config).then(response=>{
      
       var items:IApp[] = response.data

       let app:IApp[] = []

       for(let idx in items){
            
            let item = items[idx]
             
            if(item.route !== undefined){

                const r:[] = JSON.parse(item.route)
                const route = r[r.length-1]
                
                switch (route["ref_column"]) {
                  case "initialKm":
                      item.route = `Rota iniciada -  ${route["location_start"]}`
                      item.className = "initialKm"
                      break
                  case "destinyKm":
                      item.className = "destinyKm"
                      item.route = "Desembarque do passageiro"
                      break
                  case "onBoardingKm":
                      item.className = "onBoardingKm"
                      item.route = "Aguardando passageiro"
                      break
                  case "signature":
                      item.className = "signature"
                      item.route = "Rota finalizada"
                      break
                  case "boarding":
                      item.className = "boarding"
                      item.route = "Embarque iniciado"
                      break
              }
                
            }
            app.push(item)
       }       
       setApps(app)
        
       
    }).catch(error=>{
        console.error(error.response)
    })
  
  }

  useEffect(()=>{
    Apps({})
     setInterval(()=>{
      Apps({})
     },10000)
  },[])

  return (
    <div className="App">
       
       <div className="container">
        <table className="table">
            <thead>
               <tr>
                  <th>Ordem</th>
                  <th>Cliente</th>
                  <th>Motorista</th>
                  <th>Placa</th>
                  <th>Status</th>
               </tr>
            </thead>
            <tbody>
                {apps.map((item)=>{
                  return (
                    <tr key={item.id} className={item.className}>
                      <td>{item.id}</td>
                      <td>{item.customer}</td>
                      <td>{item.driver}</td>
                      <td>{item.plate}</td>
                      <td>{item.route}</td>                  
                    </tr>
                  )
                })}
            </tbody>
          </table>
       </div>
    </div>
    
  );
}

export default App;
